<template>
  <v-dialog
    v-model="dialog"
    transition="dialog-bottom-transition"
    :max-width="dialogWidth"
    scrollable
  >
    <v-card>
      <v-card-title class="pa-0">
        <v-toolbar
        >
          <v-btn
            icon
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            {{ $t('app.general.manage') }} {{ $t('app.general.actions') }}
          </v-toolbar-title>
          <v-spacer />
          <v-btn
            rounded
            color="primary"
            @click="addAction"
          >
            {{ $t('app.buttons.add') }} {{ $t('app.general.actions') }}
          </v-btn>
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="jornada.acciones"
          :items-per-page="10"
        >
          <template v-slot:item.actions="{ item }">
            <v-col class="d-flex justify-end">
              <v-btn
                icon
                @click="openItemDelete(item)"
              >
                <v-icon>
                  mdi-delete
                </v-icon>
              </v-btn>
            </v-col>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "JornadaManageActions",
  data () {
    return {
      dialog: false,
      jornada: false,
      headers: [
        {
          text: this.$t('app.jugadores.single'),
          value: 'jugador.persona.fullname',
        },
        {
          text: this.$t('app.arbitros.single'),
          value: 'arbitro.persona.fullname',
        },
        {
          text: this.$t('app.eventos.single'),
          value: 'evento.nombre',
        },
        {
          text: this.$t('app.headers.description'),
          value: 'descripcion',
        },
        {
          text: this.$t('app.headers.minute'),
          value: 'minuto',
        },
        {
          text: '',
          value: 'actions'
        }
      ]
    }
  },
  mounted() {
    EventBus.$on('jornadas-manage-actions', item => {
      this.jornada = item
      this.dialog = true
    })

    EventBus.$on('reload-journey', async () => {
      this.toggleLoader()
      await this.getJornada()
      this.toggleLoader()
    })
  },
  methods: {
    async getJornada () {
      this.$http.get(route(`v1/journey/${this.jornada.id}`))
        .then(response => {
          if (response.body.code === 200) {
            let jornada = response.body.data
            if (jornada.length > 0) {
              this.jornada = jornada[0]
            }
          } else {
            this.processError(response)
          }
        }, error => this.processError(error))
    },
    addAction () {
      EventBus.$emit('jornadas-add-actions', this.jornada)
    },
    openItemDelete (item) {
      EventBus.$emit('jornadas-action-delete', item)
    }
  }
}
</script>

<style scoped>

</style>
